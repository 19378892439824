<template>
  <div class="page-content" v-if="itemDetail">
    <section class="my-3">
      <b-card class="mt-1">
        <b-row>
          <b-col cols="3">
            <strong class="h6">Thumbnail</strong>
            <ui-component :component-data="itemDetail.thumbnail" class="border" />
          </b-col>
          <b-col cols="9">
            <b-form-group
              label="Item Name"
              label-for="h-item-name"
            >
              <ui-component :component-data="itemDetail.name" class="border" />
            </b-form-group>
            <b-form-group
              label="Item Description"
              label-for="h-item-description"
            >
              <ui-component :component-data="itemDetail.description" class="border" />
            </b-form-group>
            <b-form-group label="Type" label-for="h-type">
              <b-form-select v-model="itemDetail.type" :options="types_options" />
            </b-form-group>
            <b-form-group
              v-if="itemDetail.type == 'EQUIPMENT'"
              label="Category"
              label-for="h-category"
            >
              <b-form-select v-model="itemDetail.category" :options="category_options" />
            </b-form-group>
            <b-form-group
              v-if="itemLevel"
              label="Variant"
              label-for="h-variant"
            >
              <b-form-select v-model="itemDetail.variant" :options="itemVariant" />
            </b-form-group>
            <b-form-group
              v-if="itemDetail.type != 'BOX'"
              :label="itemLevelLabel"
              label-for="h-size"
            >
              <b-form-select v-model="itemDetail.tier" :options="itemLevel" />
            </b-form-group>
            <b-form-group v-if="itemDetail.type == 'EQUIPMENT'" label="Set item">
              <vue-select class="vue-select1" name="select1" :options="setItems" v-model="itemDetail.set_id" label="label" :reduce="label => label.value" :searchable="true" />
            </b-form-group>
            <b-form-group
              v-if="itemDetail.type == 'EQUIPMENT'"
              label="Avatar type"
              label-for="h-avatar"
            >
              <b-form-select v-model="itemDetail.character_type" :options="avatar_options" />
            </b-form-group>
            <b-form-group
            v-if="itemDetail.type == 'EQUIPMENT'"
            label="Sprite url"
            label-for="h-item-sprite-url"
          >
            <b-form-input
              v-model="itemDetail.sprite_url"
              id="item-sprite-url"
              name="item-sprite-url"
              type="text"
            />
          </b-form-group>
          <div class="d-flex">
            <label for="default-item">Default item</label>
            <b-checkbox
              class="ml-1"
              v-model="itemDetail.default"
              id="default-item"
              name="default-item"
              type="text"
            />
          </div>
          </b-col>
        </b-row>
      </b-card>
    </section>
    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteItem"
      :is-deleting="isDeleting"
    />
  </div>
</template>
<script>
import service from '../service';
import config from '../config'
import setService from '../../item_sets/service'
import VueSelect from 'vue-select'
export default {
  components:{
    VueSelect
  },
  data(){
    return{
      itemDetail: null,
      types_options:[
        {text: "Smoothies", value: "CONSUMABLE"},
        {text: "Clothes", value: "EQUIPMENT"},
        {text: "Box", value: "BOX"},
      ],
      category_options:[
        {text: "Shirt", value: "SHIRT"},
        {text: "Trousers", value: "TROUSERS"},
        {text: "Shoes", value: "SHOES"},
      ],
      avatar_options:[
        {text: "Male", value: 1},
        {text: "Female", value: 2}
      ],
      item_level_options:[],
      isUpdating: false,
      isDeleting: false,
      itemLevelLabel: null,
      setItems: [{label: "None", value: null}],
      set_id: null,
    }
  },
  computed: {
    itemId() {
      return this.$route.params.item_id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: "Item List",
          to: { name: "item-list" },
        },
      ];
      items.push({ text: "Item Detail", active: true });
      return items;
    },
    itemVariant(){
      if (this.itemDetail.type == "CONSUMABLE"){
        return config.SMOOTHY
      }
      if (this.itemDetail.type == "EQUIPMENT"){
        return config.EQUIPMENT
      }
      if (this.itemDetail.type == "BOX"){
        return null
      }
      return null
    },
    itemLevel(){
      if (this.itemVariant){
        let variant = this.itemVariant.find((x)=>{
        return x.value == this.itemDetail.variant
        })
        return variant ? variant.tier : null
      }
      return null
    }
  },
  watch:{
    "itemDetail.type":{
      deep: true,
      handler: function(newVal){
        if (newVal == "CONSUMABLE"){
          this.itemLevelLabel = "Size"
        }
        if (newVal == "EQUIPMENT"){
          this.itemLevelLabel = "Tier"
        }
      }
    },
  },
  created(){
    this.getItem();
    this.getSetItem();
  },
  methods:{
    async getItem(){
      this.itemDetail = await service.get({id: this.itemId})
    },
    async getSetItem(){
      let data = await setService.getList({
        page: 1,
        limit: 100
      })
      for (let i of data.list){
        let name = i.name.translations.find((x)=>{
          return x.language == this.$store.getters["auth/language"]
        });
        this.setItems.push({label: name ? name.value : i.name.translations[0].value, value: i._id})
      }
    },
    async update() {
      this.isUpdating = true;
      await service.update({
        data: JSON.stringify(this.itemDetail),
      });
      this.getItem();
      this.isUpdating = false;
    },
    async deleteItem() {
      await service.delete({ id: this.itemId });
      this.$router.push({ name: "items-list" });
    },
  }
}
</script>
